<template>
    <div class="viewEditor" v-if="!disabled">
      <a-tabs default-active-key="setup" tab-position="right">
<a-tab-pane key="setup" tab="Nastavenia">

  <a-form-item
                class="setup-item"
                label="Typ obsahu"
                has-feedback
        >  <a-select @change="handleChange" v-model="data['settings']['contentType']" >
            <a-select-option  :key="'contenttype:' + editid + ct.id"  :value="ct.slug"  v-for="ct in contenttypes">{{ct.title}}</a-select-option>
        </a-select>
        </a-form-item>
        <div class="mt-2" v-if="contenttypeFields">
        <a-form-item>
        Konkrétne položky <template v-if="data['settings']['specificNodes']"><strong>({{ data['settings']['specificNodes'].length }})</strong></template>
        <a-button @click="openProductSearch('specificNodes')" class="float-right">vybrať</a-button>
    </a-form-item>
    <a-form-item>
        Ignoruj položky <template v-if="data['settings']['ignoreNodes']"><strong>({{ data['settings']['ignoreNodes'].length }})</strong></template>
        <a-button @click="openProductSearch('ignoreNodes')" class="float-right">vybrať</a-button>
    </a-form-item>

            </div>
        <a-form-item v-if="nodespecific"
                     class="setup-item"
                     label="Použiť node zo stránky"
                     has-feedback
        >
            <a-switch v-if="nodespecific" :defaultChecked="data['settings']['nodeSpecific']" @change="onChange"></a-switch>
        </a-form-item>


        <a-form-item
                class="setup-item"
                label="Slovník"
                has-feedback
        >  <a-select @change="handleChangeTax" v-model="data['settings']['taxonomyfilter']" >
            <a-select-option  :key="'taxonomy:' + editid + ct.id"  :value="ct.slug"  v-for="ct in taxonomies">{{ct.title}}</a-select-option>
        </a-select>
        </a-form-item>

        <a-form-item
                class="setup-item"
                label="Jazyk"
                has-feedback
        ><a-select @change="handleChangeLang" v-model="data['settings']['lang']">
            <a-select-option :key="'taxonomy:' + editid + ct.id"  :value="ct.id"  v-for="ct in dataLang">{{ct.title}}</a-select-option>
        </a-select>
        </a-form-item>
        <a-form-item
                class="setup-item"
                label="Počet položiek"
                has-feedback
        > <a-input  v-model="data['settings']['count']" />
        </a-form-item>
        <a-form-item
                class="setup-item"
                has-feedback
        >  <a-checkbox v-model="data['settings']['published']" @change="handleChangeElement" >Zobraz nepublikované</a-checkbox>
        </a-form-item>
          <a-form-item
                class="setup-item"
                has-feedback
        >  <a-checkbox v-model="data['settings']['pagination']"  @change="handleChangeElement">Zobraz stránkovanie</a-checkbox>
        </a-form-item>
        <a-form-item
                class="setup-item"
                has-feedback
        >  <a-checkbox v-model="data['settings']['ignoreEmpty']"  @change="handleChangeElement">Schovaj blok ak prázdne</a-checkbox>
        </a-form-item>
          <a-form-item
                class="setup-item"
                label="počet položiek na stránku"
                has-feedback
        >     <a-input :disabled="!data['settings']['pagination']"  v-model="data['settings']['itemsperpage']" @change="handleChangeElement"/>
        </a-form-item>
        <a-form-item v-if="url"
                     class="setup-item"
                     has-feedback
        >
            <a-checkbox v-model="data['settings']['urlParameter']" v-if="url.includes('/*')"  @change="handleChangeElement">Použiť node z parametra</a-checkbox>
        </a-form-item>

        <a-form-item
                class="setup-item"
                has-feedback
        >  <a-checkbox v-model="data['settings']['hierarchy']" >Zahrnúť hierarchiu</a-checkbox>
        </a-form-item>

</a-tab-pane>
<a-tab-pane key="content" tab="Obsah">
  <div   v-if="contenttypeFields || data.settings.taxonomyfilter">


<div class="element">
    <a-form-item
            label="Filtrovanie"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            has-feedback
    >  <a-select mode="multiple"
    class="w-90" v-model="data['settings']['filterBy']" >
       <a-select-option key="filter:none"  value="">Nefiltrovať</a-select-option>
       <a-select-option key="filter:custom_text"  value="custom_text">Iba prepis</a-select-option>
       <a-select-option key="filter:id"  value="id">ID</a-select-option>
        <a-select-option :value="ct.id" :key="'filterfield:' + ct.id" v-for="ct in contenttypeFields.fields">{{ct.title}}</a-select-option>
    </a-select>
        <div  class="float-right">
            <a-button size="small" @click="openFilterSetup('filter')"><i class="fas fa-pencil-alt"></i></a-button>
        </div>
    </a-form-item>
</div>
<div class="element">
    <a-form-item
            label="Usporiadať"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            has-feedback
    >
        <a-select  v-model="data['settings']['orderBy']" class="w-90">
            <a-select-option key="orderby:none" value="">Neusporiadávať</a-select-option>
            <a-select-option key="ID"  value="id">ID stránky</a-select-option>
            <a-select-option key="RANDOM"  value="random">Náhodne</a-select-option>
             <a-select-option key="modified"  value="modified">Času poslednej zmeny</a-select-option>
                  <a-select-option key="datum"  value="datum">Dátumu vydania</a-select-option>
            <a-select-option :value="ct.slug" :key="'orbderbyfield:' + ct.id" v-for="ct in contenttypeFields.fields">{{ct.title}}</a-select-option>
        </a-select>
        <div  class="float-right">
          <a-button size="small" v-if="data.settings.orderBy" @click="openOrderSetup('orderby')"><i class="fas fa-pencil-alt"></i></a-button>
        </div>
    </a-form-item>
</div>
<div class="element" v-for="(el,i) in elements">
    <a-form-item :key="editid + '-fields:' + i"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol"
                 :label="fields ? (fields[el] ? fields[el].name : el) : el"
                 has-feedback
    >
        <a-select v-model="data[el]" @change="handleChangeElement"  class="w-90">
            <a-select-option :key="'contenttype:' + i + 'none'"  value="">Nezobrazovať</a-select-option>
            <a-select-option :key="'contenttype:' + i + 'custom_text'"  value="custom_text">Iba prepis</a-select-option>
            <a-select-option :value="ct.slug" :key="'contenttypefield:' + ct.id" v-for="ct in contenttypeFields.fields">{{ct.title}}</a-select-option>
        </a-select>
        <div  class="float-right">
          <a-button size="small"  @click="openSetup(el)"><i class="fas fa-pencil-alt"></i></a-button>
        </div>
    </a-form-item>
</div>
</div>

</a-tab-pane>

      </a-tabs>


    <a-modal :title="'Vyhľadávanie v položkách: ' + (productSlug == 'specificNodes' ? 'ukáž vybrané' : 'ignoruj vybrané')" :width="1000" centered v-model="productSearch" :footer="false">
      <a-row :gutter="36">
        <a-col :span="6">
<h6>Vybrané položky</h6>
<draggable v-bind="dragOptions" v-model="data['settings']['specificNodes']" group="newcomponents" @start="drag=true" @end="drag=false" handle=".handle" class="rearange-table">
  <transition-group>
<template v-for="(spn,ind) in data['settings']['specificNodes']">
  <div :key="'spn' + spn.value" class="w-100"><a class="handle mr-2"><i class="fa fa-expand-arrows-alt"></i></a>{{ spn.label }} <a class="float-right" @click="removeItemFrom('specificNodes',ind)">x</a></div>
</template>
  </transition-group>
  </draggable>
        </a-col>
     <a-col :span="18">

                 <a-form-item :label-col="{span: 24}" :wrapper-col="{span: 24}" label="Vyhľadávanie v položkách" class="mb-0">
                <a-input-search
      placeholder="Zadajte názov, značku, alebo kód položky"
     @search="(e) => fetchUser(e,data['settings']['contentType'])"
    />
            </a-form-item>


<a-spin v-if="fetching" slot="notFoundContent" size="small" />
<div class="finduser clickable-td" v-if="searchdata.length > 0" style="max-height:300px;overflow-y:scroll">
<div class="mb-3">Výsledky vyhľadávania:</div>
    <table class="w-100" >
        <template  v-for="user in searchdata">
                    <tr :key="user.id" class="selectable-row">
                    <td v-if="user.image" style="width:60px;text-align:center;"><span><img :src="user.image.styled" height="40"></span></td>
                    <td v-else style="width:60px;text-align:center;">-</td>
                    <td style="width:200px;"><span>{{user.value}}</span></td>
                    <td style="width:150px;display:block;">{{user.url}}</td>
                    <td v-if="user.price" style="width:100px;display:block;text-align:right;">{{user.price}} €</td>
                    <td v-else style="width:100px;display:block;text-align:center;">-</td>
                    <td style="width:100px;text-align:right;display:block;"><a-button @click="addProductItem(user)">pridať</a-button></td>
                    </tr>
        </template>
    </table>
</div>
</a-col>

</a-row>
      </a-modal>


        <a-modal title="Nastavenie poľa" centered v-model="setupVisible" @ok="handleOk">
            <div class="additional-setup">
                <a-form-item :key="selectedElement + '-fields:'" v-if="selectedElement === 'row.image'" :label-col="labelCol" :wrapper-col="wrapperCol" label="Štýl obrázka" has-feedback>
                    <a-select  :defaultValue="data[selectedElement+'_style']" placeholder="štýl obrázka" v-model="data[selectedElement+'_style']" :options="styles"/>
                </a-form-item>
                <a-form-item :key="selectedElement + '-fields:'" v-if="selectedElement === 'row.image'" :label-col="labelCol" :wrapper-col="wrapperCol" placeholder="šírka:výška" label="Vlastný rozmer" has-feedback>
                    <a-input  :defaultValue="data[selectedElement+'_style']" placeholder="štýl obrázka" v-model="data[selectedElement+'_style']"/>
                </a-form-item>

                <a-form-item :key="selectedElement + '-fieldsSetup'" :label-col="labelCol" :wrapper-col="wrapperCol" label="Prepis poľa" has-feedback>
                    <textarea ref="yourTextarea" class="ant-input" :key="'override:' + selectedElement" v-model="data.settings.templates[selectedElement]" :rows="4"/>
                    <ul class="override-text">
                        <li v-for="element in elements"><a @click="copypaste(selectedElement,element)">{{element}}</a></li>
                        <li>----------------</li>
                        <li v-for="element in contenttypeFields.fields"><a @click="copypaste(selectedElement,element.slug)">{{element.title}}</a></li>
                    </ul>
                </a-form-item>
            </div>
        </a-modal>

        <a-modal title="Kontextuálny filter" centered v-model="filterVisible" @ok="handleFilterOk">

        <div>

             <template v-for="(filterit,oio) in data.settings.filterBy" v-if="selectedFields[filterit]">

<a-form-item  :label="selectedFields[filterit].title"  :key="oio">
                <a-select v-if="filterOptions['f' + filterit]"
                          mode="multiple"
                          style="width: 100%"
                          :defaultValue = "data.settings.filter[filterit] ? JSON.parse(data.settings.filter[filterit]) : ''"
                          @change="(e) => filterbyChange(e,filterit)"
                          placeholder="Vyberte ktoré termíny zobraziť">
                    <a-select-option v-for="i in filterOptions['f' + filterit]" :key="i.id">{{i.title}}</a-select-option>
                </a-select>

                </a-form-item>
                <template  v-if="selectedFields[filterit].type == '5'" >

                   <a-select   mode="multiple" style="width: 100%"   @change="(e) => filterbyChange(e,filterit)">
    <a-select-option v-for="i in 25" :key="i">
      {{ i }}
    </a-select-option>
  </a-select>

                    </template>

                    </template>
                </div>
                <a-form-item v-if="nodespecific"
                             class="setup-item"
                             label="Odfiltrovať node z otvorenej stránky"
                             has-feedback>
                    <a-switch v-if="nodespecific" :defaultChecked="data['settings']['ingoreNodeOn']" @change="onChangen"></a-switch>
                </a-form-item>

                <a-form-item v-if="nodespecific || existField"
                             class="setup-item"
                             label="Použiť hodnotu z otvorenej stránky"
                             has-feedback>
                    <a-switch v-if="nodespecific || existField" :defaultChecked="data['settings']['fieldTaxonomy']" @change="onChangea"></a-switch>
                </a-form-item>

                <a-form-item v-if="vocabulary"
                             class="setup-item"
                             label="Použiť termín taxonómie zo stránky"
                             has-feedback
                >
                    <a-switch v-if="vocabulary" :defaultChecked="data['settings']['taxonomySpecific']" @change="onChanget"></a-switch>
                </a-form-item>

        </a-modal>
        <a-modal title="Usporiadanie" centered v-model="orderbyVisible" @ok="handleOrderbyOk">
            <div class="additional-setup">
                <a-select style="width: 100%"
                          :defaultValue = "data.settings.dir"
                          @change="orderbyChange">
                    <a-select-option key="ASC">Vzostupne</a-select-option>
                    <a-select-option key="DESC">Zostupne</a-select-option>
                </a-select>
            </div>
        </a-modal>

    </div>
</template>

<script>
    import {dataService} from "../../_services";
    import AFormItem from "ant-design-vue/es/form/FormItem";
    import draggable from "vuedraggable";

    export default {
        name: "ViewEditor",
        components: {AFormItem, draggable},
        props: ["data","values","elements","editid","nodespecific","disabled","vocabulary","url","fields","selectedCT","templatesCustom"],
        data() {
            return {
                size: 'small',
                newComponent: null,
                componentId: 0,
                templated: {},
                dataLang: [],
                styles: [],
                override:[],
                orderbyVisible:false,
                template:"",
                setup:[],
                productSlug:"",
                taxonomies: {},
                productSearch:false,
                setupVisible:false,
                nodes:[],
                templates: [],
                lastFetchId:0,
                addtogo:false,
                mainFields: [],
                searchdata:[],
                selectedFields: {},
                searchResults:[],
                selectedElement: '',
                txtContent: '',
                imagetoshow: {},
                fetching:false,
                existField:false,
                filterOptions:[],
                contenttypes: [],
                contenttypeFields: false,
                dattos: {'settings': {'contentType': '','specificNodes':[],'ignoreNodes':[],'orderby': '','orderbyDirection': '','templates':{}}},
                visible: false,   labelCol: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                },
                editVisible: false,
                filterVisible: false
            };
        },
        methods: {
            handleChange(id){
                if(id)
                {
                dataService.axiosFetch('contenttype',id).then(results => {
                    this.contenttypeFields = results;

    results.fields.forEach(obj => {

this.selectedFields[obj.id] = obj;

                     });

                });
                this.$emit('changed', this.data);
            }},
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },
            handleChangeTax(id){

                dataService.axiosFetch('taxonomy',id).then(results => {
                    this.contenttypeFields = results;
                });

                this.$emit('changed', this.data);
            },
            removeItemFrom(slug,ind)
            {
              this.data['settings'][slug].splice(ind, 1).filter(val => val);
            },
            fetchUser(value,ct) {
      console.log('fetching user', value,ct);
      this.lastFetchId += 1;
      this.searchdata = [];
      this.fetching = true;
      this.fetching = false;
      dataService.axiosPost('findproducts', {searchterm: value,ct:ct}).then(results => {
this.searchdata = results.data.data;
});

    },
    addProductItem(theItem)
    {
      console.log(theItem);
      if(!this.data['settings']['specificNodes'])
      this.data['settings']['specificNodes'] = [];
this.data['settings']['specificNodes'].push({value: theItem.entity_id, 'label': theItem.value});
    },
            openOverride(el)
            {
                this.override[el] = !this.override[el];
            },
            handleChangeLang(id)
            {
                this.$emit('changed', this.data);
            },
            findItAll(ef)
            {

var e = ef.target.value;
              if(e.length > 2)
            {dataService.axiosPost('findnodes', {searchterm: e}).then(results => {

              this.searchResults = results.data.data;
            });
            }
              else
              {this.searchResults = [];}

              },
            openFilterSetup(el)
            {

 this.addtogo = false;
                if(!this.data.settings.filter)
                    this.data.settings.filter = {};
                this.filterOptions = {};
                var filterby = this.data.settings.filterBy;


                this.existField = false;

               var totlength = this.contenttypeFields.fields.length;
                var ctfi = 0;
                this.contenttypeFields.fields.forEach(obj => {


ctfi = ctfi + 1;
if(filterby)
{
if(filterby.includes(obj.id) && obj.type === "12" )
{
                if(this.contenttypeFields.context)
                {
                // eslint-disable-next-line no-prototype-builtins
                if(this.contenttypeFields.context.hasOwnProperty(obj.id))
                {
        dataService.axiosFetch('taxonomyitems',this.contenttypeFields.context[obj.id]).then(results => {
                       this.filterOptions['f' + obj.id] = results;

      this.addtogo = true;
                    });
                }
                }
}
                    if(obj.id === filterby)
                    {
                        if(this.mainFields.includes(obj.slug))
                        {this.existField = true;}
                    }
                    if(filterby.includes(obj.id) && obj.type === "7"){
                      if(!this.data.settings.filter[obj.id])
                    this.data.settings.filter[obj.id] = [];
                      this.filterOptions['f' + obj.id] = [{'id':1, "title": "Áno"},{'id':0, "title": "Nie"}];
                    }

                    if(filterby.includes(obj.id) && obj.type === "2"){
                    if(!this.data.settings.filter[obj.id])
                    this.data.settings.filter[obj.id] = [];
                        this.filterOptions['f' + obj.id] = this.dataLang;
                    }

                    if(ctfi === totlength)
                {
                     if(Object.keys(this.filterOptions).length === filterby.length)
{

                    this.addtogo = true;
}


                }  }  this.filterVisible = true;
                });



            },
            filterbyChange(value,fid)
            {
                if(!this.data.settings.filter === "[]")
                this.data.settings.filter = JSON.parse(this.data.settings.filter);
console.log(this.data.settings)
                this.data.settings.filter[fid] = JSON.stringify(value);
            },
            openOrderSetup(el)
            {
                if(!this.data.settings.orderBy)
                {this.data.settings.orderBy ="";
                    this.data.settings.dir ="ASC";
                }
                this.orderbyVisible = true;


            },
            orderbyChange(value)
            {
                this.data.settings.dir = value;
            },

            handleOrderbyOk()
            {
                this.orderbyVisible = false;
                this.$emit('changed', this.data);
            },
            handleSearch(e)
            {
console.log(e);
            },
            openSetup(el)
            {
                this.selectedElement = el;
                this.setupVisible = true;

            },
            handleChangeElement()
            {
                this.$emit('changed', this.data);
            },
            handleOk()
            {


                this.setupVisible = false;
                this.selectedElement = null;

                this.$emit('changed', this.data);
            },
            handleFilterOk()
            {

                this.filterVisible = false;
            },
            onChange(e)
            {
                this.data.settings.nodeSpecific = e;

            },
            onChanget(e)
            {
                this.data.settings.taxonomySpecific = e;

            },
            onChangen(e)
            {
                this.data.settings.ingoreNodeOn = e;

            },
            onChangea(e)
            {
                this.data.settings.fieldTaxonomy = e;

            },
            openProductSearch(slug)
            {
              this.productSearch = true;
              this.productSlug = slug;


            },
            copypaste(el,v)
            {

                const self = this;
                let tArea = this.$refs.yourTextarea;

                let startPos = tArea.selectionStart,
                    endPos = tArea.selectionEnd,
                    cursorPos = startPos,
                    tmpStr = tArea.value;

                self.data.settings.templates[el] = tmpStr.substring(0, startPos) + '*' + v + '*' + tmpStr.substring(endPos, tmpStr.length);
                // move cursor:
                setTimeout(() => {
                    cursorPos += v.length;
                    tArea.selectionStart = tArea.selectionEnd = cursorPos;
                }, 10);
            }
        },
        mounted() {



            dataService.axiosFetch('contenttypes').then(results => {
                this.contenttypes = results;
            });
            dataService.axiosFetch('taxonomies').then(results => {
                this.taxonomies = results;
            });
            dataService.axiosFetch('languages').then(results => {
                this.dataLang.push({"id":'und',"title":"Nezávislé od jazyka"});
                results.forEach(obj => { this.dataLang.push({"id" : obj.short , "title" : obj.title}); });
            });
            if(this.data.settings.contentType)
            {
                dataService.axiosFetch('contenttype',this.data.settings.contentType).then(results => {
                    this.contenttypeFields = results;

    results.fields.forEach(obj => {

this.selectedFields[obj.id] = obj;

                     });

                });


                dataService.axiosFetch('contenttype',this.selectedCT).then(results => {
                     results.fields.forEach(obj => { this.mainFields.push(obj.slug); });

                     results.fields.forEach(obj => {

this.selectedFields[obj.id] = obj;

                     });
                });

                if(Array.isArray(this.data.settings.templates))
               { this.data.settings.templates = {};}
            }
            else {
                this.data.settings.contentType = "";
               this.data.settings.templates = {};
            }
            if(this.vocabulary)
            {
                dataService.axiosFetch('taxonomy',this.vocabulary).then(results => {
                    this.contenttypeFields = results;
                });
            }



        }
    }
</script>

<style scoped>
    .elements >>> .ant-select
    {width:95%}
    .elements >>> .ant-row
    {margin-bottom:0px}

    .elements
    {width:60%;display:inline-block;padding-left:5%;}
    .element >>> .ant-form-item-label
    {width:100%; text-align:left;line-height:20px;}
    .element >>> .ant-form-item-label label
    {margin:0;padding:0;margin-top:5px;margin-bottom:5px;}

    .field-setup
    {display:inline;float:right;width:5%}
    .field-setup i{
        font-size:14px; padding:14px;
        color: #33325e;
        float:left;margin:0;
    }
    .additional-setup >>> .ant-select
    {
        width:100%;
    }
    .override-text
    {    line-height: 17px;
    }
    .override-text li{
        margin:0;
    }
    .field-setup i:hover
    {background-color:rgba(255,255,255,.7);cursor:pointer;}

    .setup-item >>> .ant-select
    {width:100%;}

</style>
